import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { OuterClick } from 'react-outer-click';
import { pure, compose, withHandlers} from 'react-recompose';
// components
import { TextBtn1 } from '../../../components/text-btns';
import { OrderChargesTotal } from '../../../components/charge/components';
import { DOChargeComponent } from '../../../components/charge/formik/charges-array';
import { withRecalculateAllChargesId, getSharedAccessorialsApplyTo } from '../../../components/charge/hocs';
import { withAsyncChargeAccessorials } from '../../../components/charge/hocs/with-async-charge-accessorials';
// features
import { cloRateEmptyFields } from '../../rate/customer/settings';
import { withHandleCloRateAvailableRates } from '../../rate/customer/hocs';
// feature new-do
import { isPageCloTemplate } from '../../new-do/helpers';
import SectionDivider from '../../new-do/components/section-divider';
import { FORM_BOX_SHADOW, TAB_NAME_REFERENCE } from '../../new-do/constants';
import { withAsyncLoadDistance, withSearchLocationSetValues } from '../../new-do/hocs';
// forms
import { Fieldset2 } from '../../../forms/formik';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetAccessorialsList } from '../../../helpers/api-async';
// hocs
import {
  withConnectModalAndLoaderActions,
  withComponentDidUpdatePropCallback,
  withAsyncSearchTemplateAutocomplete,
} from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature lite-new-do
import FormTab from './form-tab';
import PrevNextAction from './validate-action';
import { setDefaultCustomerFormValues } from '../helpers';
import { isValidBillToCustomerRateForm, validationSchemaBillToCustomerRate } from '../validation';
import {
  commentFields,
  contactFields,
  contactFields2,
  contactFields3,
  mainBillToFields,
  customerRateFields,
  addressBillToFields,
  addressBillToFields2,
} from '../settings';
//////////////////////////////////////////////////

const CustomerCharges = (props: Object) => {
  const {
    services,
    branchGuid,
    isEditMode,
    isCreateMode,
    stopsQuantity,
    recalculateAllChargesId,
    defaultOrderAccessorials,
    sharedAccessorialsApplyTo,
    defaultOrderFuelAccessorial,
  } = props;

  const commonTotalProps = G.getTotalsFromValues(props);

  const currency = R.pathOr('', ['values', GC.FIELD_CURRENCY], props);
  const serviceDays = R.pathOr('', ['values', GC.FIELD_SERVICE_DAYS], props);
  const totalQuantities = R.path(['loadTotals', GC.FIELD_TOTAL_QUANTITIES], props);
  const totalTripVolume = R.pathOr(0, ['itemsVolume', GC.ITEMS_VOLUME], props);
  const totalTripVolumeUom = R.pathOr('', ['itemsVolume', GC.ITEMS_VOLUME_UOM], props);
  const normalizedTotal = R.pathOr({}, ['values', GC.SYSTEM_OBJECT_NORMALIZED_TOTAL], props);

  return (
    <div>
      <DOChargeComponent
        {...commonTotalProps}
        rateProps={props}
        isCustomer={true}
        currency={currency}
        services={services}
        branchGuid={branchGuid}
        loadType={GC.FIELD_CLO}
        isEditMode={isEditMode}
        serviceDays={serviceDays}
        isCreateMode={isCreateMode}
        stopsQuantity={stopsQuantity}
        totalQuantities={totalQuantities}
        normalizedTotal={normalizedTotal}
        totalTripVolume={totalTripVolume}
        chargesArrayName={GC.FIELD_CHARGES}
        totalTripVolumeUom={totalTripVolumeUom}
        recalculateAllChargesId={recalculateAllChargesId}
        defaultOrderAccessorials={defaultOrderAccessorials}
        sharedAccessorialsApplyTo={sharedAccessorialsApplyTo}
        defaultOrderFuelAccessorial={defaultOrderFuelAccessorial}
      />
    </div>
  );
};

const getFormData = (values: Object) => {
  const isValid = isValidBillToCustomerRateForm(values);

  return R.assoc('isValid', isValid, values);
};

const CustomerRateForm = (props: Object) => {
  const {
    values,
    pageType,
    isEditMode,
    submitForm,
    branchConfigs,
    normalizedTotal,
    handleClickPrev,
    handleCleanForm,
    handleOuterClick,
    handleGetLoadOptions,
    handleUpdateCloTemplate,
    handleOpenAutoRateModal,
    handleAsyncSearchSelectChange,
    handleSetRecalculateAllChargesId,
    handleGetSharedAccessorialsApplyTo,
   } = props;

  const charges = G.getChargesFromObject(values);

  const rateContactFields = R.map(
    (item: Object) => R.assoc('fieldName', `rateContact.${G.getPropFromObject('fieldName', item)}`, item),
    contactFields3,
  );

  const headerTitle = `${G.getWindowLocale('titles:customer', 'Customer')}
    ${G.getWindowLocale('titles:bill-to', 'Bill To')} / ${G.getWindowLocale('titles:rate', 'Rate')}`;

  return (
    <OuterClick onOuterClick={handleOuterClick}>
      <form>
        <FormTab {...props} />
        <Flex
          p='7px 10px'
          justifyContent='space-between'
          color={G.getTheme('colors.black')}
          bg={G.getTheme('colors.light.teaGreen')}
        >
          <Box>{headerTitle}</Box>
          <Flex>
            <TextBtn1
              onClickHandler={handleSetRecalculateAllChargesId}
              text={G.getWindowLocale('titles:recalc-all-charges', 'Recalc All Charges')}
            />
            <TextBtn1
              onClickHandler={handleGetSharedAccessorialsApplyTo}
              text={G.getWindowLocale('titles:update-auto-acc', 'Update Auto Acc.')}
            />
            <TextBtn1
              onClickHandler={handleOpenAutoRateModal}
              text={G.getWindowLocale('titles:show-rates', 'Show Rates')}
            />
            <Box
              mx='7px'
              cursor='pointer'
              onClick={handleCleanForm}
              title={G.getWindowLocale('titles:clean-form', 'Clean Form')}
            >
              {I.clean(G.getTheme('colors.dark.blue'))}
            </Box>
          </Flex>
        </Flex>
        <Box boxShadow={FORM_BOX_SHADOW}>
          <Box pt={20}>
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={mainBillToFields}
              branchConfigs={branchConfigs}
              fieldsWrapperStyles={{ px: 10 }}
              getLoadOptions={handleGetLoadOptions}
              handleAsyncSearchSelectChange={handleAsyncSearchSelectChange}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:address', 'Address', { caseAction: 'upperCase' })}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={addressBillToFields}
              fieldsWrapperStyles={{ mb: 30, px: 10 }}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={addressBillToFields2}
              fieldsWrapperStyles={{ px: 10 }}
            />
            <SectionDivider
              text={G.getWindowLocaleArr(
                ['titles:bill-to', 'titles:contact'], 'Bill To Contact', { caseAction: 'upperCase' },
              )}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={contactFields}
              fieldsWrapperStyles={{ mb: 30, px: 10 }}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={contactFields2}
              fieldsWrapperStyles={{ px: 10 }}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:comments', 'Comments', { caseAction: 'upperCase' })}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={commentFields}
              fieldsWrapperStyles={{ mb: 30, px: 10 }}
            />
            <SectionDivider
              text={G.getWindowLocaleArr(
                ['titles:customer', 'titles:rate'], 'Customer Rate', { caseAction: 'upperCase' },
              )}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={rateContactFields}
              fieldsWrapperStyles={{ mb: 30, px: 10 }}
            />
            <Fieldset2
              {...G.getFormikProps(props)}
              fields={customerRateFields}
              {...G.getModeServiceFromBranchConfigsForRateSelect(props)}
              fieldsWrapperStyles={{ mb: 10, px: 10, flexWrap: 'nowrap' }}
            />
            <CustomerCharges {...props} />
            <OrderChargesTotal
              values={values}
              charges={charges}
              isEditMode={isEditMode}
              normalizedTotal={normalizedTotal}
            />
            <PrevNextAction
              showNext={true}
              handleClickPrev={handleClickPrev}
              nextBtnText={G.getWindowLocale('actions:submit', 'Submit')}
              submitForm={G.ifElse(isPageCloTemplate(pageType), handleUpdateCloTemplate, submitForm)}
            />
          </Box>
        </Box>
      </form>
    </OuterClick>
  );
};

const defaultEnhance = compose(
  withAsyncLoadDistance,
  withConnectModalAndLoaderActions,
  withAsyncChargeAccessorials({ isCustomer: true, isCreateMode: true }),
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaBillToCustomerRate,
    mapPropsToValues: (props: Object) => setDefaultCustomerFormValues(props),
    handleSubmit: (values: Object, { props }: Object) => {
      const { handleClickSave, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
      setTimeout(() => handleClickSave(), 500);
    },
    displayName: 'CustomerForm',
  }),
  withAsyncSearchTemplateAutocomplete({
    useCurrentBranch: true,
    shouldGetLocationTemplateSearchRequestProps: true,
  }),
  withSearchLocationSetValues,
  withHandlers({
    onClickTabCallback: (props: Object) => (activeName: Object) => {
      const { values, setActiveLeftTab, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
      setActiveLeftTab(activeName);
    },
    handleClickPrev: (props: Object) => () => {
      const { values, setActiveLeftTab, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
      setActiveLeftTab(TAB_NAME_REFERENCE);
    },
    handleOuterClick: (props: Object) => () => {
      const { values, setFormDataToStore } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });
    },
    handleSetCustomerRateFromAvailableRates: ({ setFormDataToStore }: Object) => (values: Object) =>
      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) }),
    handleUpdateCloTemplate: (props: Object) => () => {
      const { values, templateData, setFormDataToStore, saveAsTemplateRequest } = props;

      setFormDataToStore({ dataName: 'rate', formData: getFormData(values) });

      saveAsTemplateRequest({
        mode: 'updateTemplate',
        [GC.FIELD_TEMPLATE_NAME]: R.path([GC.FIELD_TEMPLATE_NAME], templateData),
      });
    },
  }),
  withHandleCloRateAvailableRates,
  pure,
);

const customerRateEnhance = compose(
  withRecalculateAllChargesId,
  defaultEnhance,
  withHandlers({
    handleGetSharedAccessorialsApplyTo: (props: Object) => async () => {
      const { stops, values, branchGuid, setFormDataToStore, handleSetRecalculateAllChargesId } = props;

      const accessorials = await asyncGetAccessorialsList(branchGuid);
      const sharedApplyTo = await getSharedAccessorialsApplyTo(
        { ...props, accessorials, stopCount: R.length(R.values(stops)) },
        branchGuid,
      );

      const charges = G.getChargesFromObject(values);
      const newCharges = G.replaceAutoChargesWithNewSharedAssessorialsApplyTo(charges, sharedApplyTo);
      const data = R.assoc(GC.FIELD_CHARGES, newCharges, values);

      setFormDataToStore({ dataName: 'rate', formData: getFormData(data) });
      handleSetRecalculateAllChargesId();
    },
    handleCleanForm: ({ values, setValues }: Object) => () => setValues(R.mergeRight(values, cloRateEmptyFields)),
  }),
  withHandlers({
    handleTotalTripDistanceChange: (props: Object) => (name: string, currProps: Object) => {
      const { values, setValues } = props;

      if (G.isNilOrEmpty(values)) return;

      const totalTripDistance = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE], currProps);
      const totalTripDistanceUom = R.path([GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], currProps);

      setValues(R.mergeRight(values, { totalTripDistance, totalTripDistanceUom }));
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'totalTripDistance',
    callbackName: 'handleTotalTripDistanceChange',
  }),
);

export default customerRateEnhance(CustomerRateForm);
