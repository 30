import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../../components/text';
// features
import { columnSettings as itemColumnSettings } from '../../../item/settings/column-settings';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  PAYMENT_TERM_OPTIONS,
  getFreightClassOptions,
  ITEM_DIMENSIONS_OPTIONS,
  ITEM_ODOMETER_UOM_OPTIONS,
} from '../../../../helpers/options';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex } from '../../../../ui';
// utilities
import routesMap from '../../../../utilities/routes';
// feature invoice
import { DETAILS_CELL } from '../../constants';
//////////////////////////////////////////////////

const itemColumnSettingsForCustomerInvoice = R.compose(
  R.reduce((acc: Object, item: string) => {
    let locArr = ['titles:items'];
    const key = `${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${item}`;
    const itemLoc = R.path([item, GC.FIELD_NAME], itemColumnSettings);

    if (G.isArray(itemLoc)) {
      locArr = R.concat(locArr, itemLoc);
    } else {
      locArr = R.append(itemLoc, locArr);
    }

    return R.assoc(key, { ...R.prop(item, itemColumnSettings), collection: true, [GC.FIELD_NAME]: locArr }, acc);
  }, {}),
  R.keys,
  R.omit([
    GC.FIELD_ACTIVE,
    GC.FIELD_ODOMETER_UOM,
    GC.FIELD_WHEELBASE_UOM,
    GC.FIELD_ITEM_FREIGHT_CLASS,
    GC.FIELD_ITEM_DIMENSIONS_UOM,
  ]),
)(itemColumnSettings);

export const getColumnSettings = (props: Object) => ({
  [DETAILS_CELL]: {
    width: 220,
    notResizable: true,
    customComponent: ({ data }: Object) => {
      const { handleClickRFB, handleOpenCloDetails } = props;

      const systemStatus = R.path([GC.FIELD_SYSTEM_STATUS], data);
      const readyForBilling = R.equals(systemStatus, GC.INVOICE_SYSTEM_STATUS_READY_FOR_BILLING);
      const colorLightBlue = G.getTheme('colors.light.blue');

      return (
        <Flex>
          <Box
            mr={20}
            cursor='pointer'
            onClick={() => handleOpenCloDetails(G.getPropFromObject(GC.FIELD_CLO_GUID, data))}
          >
            {I.routesLoads(colorLightBlue)}
          </Box>
          {
            readyForBilling &&
            <Flex>
              <Box
                mr={20}
                color={G.getTheme('colors.green')}
                title={G.getWindowLocale('titles:ready-for-billing', 'Ready For Billing')}
              >
                {G.getWindowLocale('titles:rfb', 'RFB')}
              </Box>
              <Box
                cursor='pointer'
                color={colorLightBlue}
                textDecoration='underline'
                onClick={() => handleClickRFB({ data, ready: true })}
                title={G.getWindowLocale('titles:mark-as-not-ready-for-billing', 'Mark As Not Ready For Billing')}
              >
                {G.getWindowLocale('titles:mark-as-unready', 'Mark As Unready')}
              </Box>
            </Flex>
          }
          {
            R.not(readyForBilling) &&
            <Flex>
              <Box
                cursor='pointer'
                textDecoration='underline'
                color={G.getTheme('colors.light.blue')}
                onClick={() => handleClickRFB({ data, ready: false })}
                title={G.getWindowLocale('titles:mark-as-ready-for-billing', 'Mark As Ready For Billing')}
              >
                {G.getWindowLocale('titles:mark-as-ready', 'Mark As Ready')}
              </Box>
            </Flex>
          }
        </Flex>
      );
    },
  },
  [`${GC.FIELD_CLO}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`]: {
    width: 200,
    name: 'titles:branch',
  },
  [`${GC.FIELD_CLO}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`]: {
    width: 200,
    name: 'titles:account-number',
  },
  [GC.FIELD_CURRENCY]: {
    width: 200,
    name: 'titles:currency',
  },
  [GC.FIELD_TOTAL]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:invoice-total',
  },
  [GC.FIELD_MAIN_CHARGES_TOTAL]: {
    width: 120,
    pivotType: 'number',
    name: 'titles:linehaul',
  },
  [GC.FIELD_DISCOUNT_CHARGES_TOTAL]: {
    width: 120,
    pivotType: 'number',
    name: 'titles:discount',
  },
  [GC.FIELD_FUEL_CHARGES_TOTAL]: {
    width: 120,
    name: 'titles:fuel',
    pivotType: 'number',
  },
  [GC.FIELD_ADDITIONAL_CHARGES_TOTAL]: {
    width: 150,
    pivotType: 'number',
    name: 'titles:other-accessorials',
  },
  [GC.FIELD_BALANCE]: {
    width: 150,
    pivotType: 'number',
    name: 'titles:balance',
  },
  [GC.FIELD_PAID_TOTAL]: {
    width: 150,
    pivotType: 'number',
    name: 'titles:paid-total',
  },
  [`${GC.FIELD_CLO}.${GC.GRC.RATE_TOTAL}`]: {
    width: 200,
    pivotType: 'number',
    name: ['titles:rate', 'titles:total'],
  },
  [`${GC.FIELD_CLO}.${GC.GRC.RATE_CURRENCY}`]: {
    width: 200,
    name: ['titles:rate', 'titles:currency'],
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_STATUS]: {
    width: 200,
    name: 'titles:integration-status',
    customComponent: ({ data }: Object) => {
      const status = R.pathOr(' ', [GC.GRC.ACCOUNTING_INTEGRATION_STATUS], data);
      const text = G.getWindowLocale(GC.statusLocaleMap[status], status);
      return (
        <span title={text}>{text}</span>
      );
    },
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_TYPE]: {
    width: 200,
    name: 'titles:integration-type',
  },
  [GC.FIELD_EXTERNAL_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:external-invoice-number',
  },

  [GC.GRC.ACCOUNTING_INTEGRATION_GL_POSTED_DATE]: {
    name: 'titles:gl-posted-date',
    pivotType: GC.PIVOT_TYPE_YQMD,
  },
  [GC.GRC.ACCOUNTING_INTEGRATION_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:integrated-by',
  },
  [GC.GRC.BILL_TO_LOCATION_NAME]: {
    width: 200,
    name: 'titles:bill-to',
  },
  [GC.GRC.BILL_TO_TEMPLATE_ID]: {
    width: 200,
    name: ['titles:bill-to', 'titles:template-id'],
  },
  [GC.GRC.BILL_TO_LOCATION_TYPE_DISPLAYED]: {
    width: 200,
    name: ['titles:bill-to', 'titles:type'],
  },
  [GC.GRC.BILL_TO_COUNTRY]: {
    width: 200,
    name: ['titles:bill-to', 'titles:country'],
  },
  [GC.GRC.BILL_TO_STATE]: {
    width: 200,
    name: ['titles:bill-to', 'titles:state'],
  },
  [GC.GRC.BILL_TO_CITY]: {
    width: 200,
    name: ['titles:bill-to', 'titles:city'],
  },
  [GC.GRC.BILL_TO_ADDRESS1]: {
    width: 200,
    name: ['titles:bill-to', 'titles:address1'],
  },
  [GC.GRC.BILL_TO_ZIP]: {
    width: 200,
    name: ['titles:bill-to', 'titles:zip'],
  },
  [GC.GRC.BILL_TO_COMMENTS]: {
    width: 200,
    name: ['titles:bill-to', 'titles:comments'],
  },
  [GC.GRC.BILL_TO_PAYMENT_TERM]: {
    width: 200,
    name: ['titles:bill-to', 'titles:payment-terms'],
  },
  [GC.GRC.CLO_BILL_TO_EMAILS]: {
    width: 200,
    name: ['titles:bill-to', 'titles:emails'],
  },
  [GC.GRC.REMIT_TO_LOCATION_NAME]: {
    width: 200,
    name: 'titles:remit-to',
  },
  [GC.GRC.REMIT_TO_LOCATION_TYPE_DISPLAYED]: {
    width: 200,
    name: ['titles:remit-to', 'titles:type'],
  },
  [GC.GRC.REMIT_TO_COUNTRY]: {
    width: 200,
    name: ['titles:remit-to', 'titles:country'],
  },
  [GC.GRC.REMIT_TO_STATE]: {
    width: 200,
    name: ['titles:remit-to', 'titles:state'],
  },
  [GC.GRC.REMIT_TO_CITY]: {
    width: 200,
    name: ['titles:remit-to', 'titles:city'],
  },
  [GC.GRC.CLO_ORDER_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:order-type',
  },
  [GC.GRC.REMIT_TO_ADDRESS1]: {
    width: 200,
    name: ['titles:remit-to', 'titles:address1'],
  },
  [GC.GRC.REMIT_TO_ZIP]: {
    width: 200,
    name: ['titles:remit-to', 'titles:zip'],
  },
  [GC.GRC.REMIT_TO_COMMENTS]: {
    width: 200,
    name: ['titles:remit-to', 'titles:comments'],
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    name: 'titles:type',
  },
  [GC.GRC.STATUS_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:status',
    customComponent: ({ data }: Object) => R.pathOr(
      R.prop(GC.GRC.STATUS_DISPLAYED_VALUE, data),
      [GC.FIELD_INVOICE_STATUS, GC.FIELD_DISPLAYED_VALUE],
      data,
    ),
  },
  [GC.FIELD_COMMENTS]: {
    width: 400,
    name: 'titles:comments',
  },
  [GC.GRC.MODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:mode',
  },
  [GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:service-type',
  },
  [GC.FIELD_SERVICE_DAYS]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:service-days',
  },
  [GC.GRC.CLO_STORED_TOTAL_WEIGHT]: {
    width: 200,
    pivotType: 'number',
    type: GC.FIELD_WEIGHT,
    name: 'titles:order-total-weight',
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:total-invoice-weight',
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: {
    width: 200,
    name: 'titles:total-invoice-weight-uom',
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:total-trip-distance',
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE_UOM]: {
    width: 200,
    name: 'titles:total-trip-distance-uom',
  },
  [GC.GRC.CLO_TOTAL_SPENDING]: {
    width: 200,
    pivotType: 'number',
    name: ['titles:clo', 'titles:total-spend'],
  },
  [GC.FIELD_INVOICE_PAYMENT_DUE_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: 'titles:payment-due-date',
  },
  [GC.FIELD_INVOICE_NET_DAYS]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:net-days',
  },
  [GC.FIELD_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:invoice-number',
  },
  [GC.FIELD_INVOICE_DATE]: {
    name: 'titles:invoice-date',
    pivotType: GC.PIVOT_TYPE_YQMD,
  },
  [GC.FIELD_INTEGRATED_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: 'titles:integrated-date',
  },
  [GC.GRC.PAYMENTS_DEPOSIT_DATE]: {
    name: 'titles:deposit-date',
  },
  [GC.GRC.PAYMENTS_CHECK_NUMBER]: {
    width: 200,
    name: 'titles:check-number',
  },
  [GC.GRC.PAYMENTS_CHECK_DATE]: {
    name: 'titles:check-date',
  },
  [GC.GRC.PAYMENTS_CHECK_AMOUNT]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:check-amount',
  },
  [GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER]: {
    width: 200,
    name: 'titles:ach-payment-confirmation',
  },
  [GC.FIELD_MASTER_INVOICE_NUMBER]: {
    width: 200,
    name: 'titles:master-invoice-number',
  },
  [GC.FIELD_PHONE]: {
    width: 200,
    name: 'titles:phone',
  },
  [GC.FIELD_FAX]: {
    width: 200,
    name: 'titles:fax',
  },
  [GC.FIELD_EMAIL]: {
    width: 200,
    name: 'titles:email',
  },
  [GC.FIELD_CONTACT_NAME]: {
    width: 200,
    name: 'titles:contact-name',
  },
  [GC.GRC.CLO_ROUTE_NAME]: {
    width: 250,
    name: 'titles:route-name',
  },
  [GC.GRC.CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    useExpandedContainer: true,
    openContainerAsNewWindow: true,
    guidPropName: GC.FIELD_CLO_GUID,
    type: GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
    name: ['titles:clo', 'titles:primary-reference'],
  },
  [GC.GRC.CLO_SALE_PERSONS_NAME]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:name'],
  },
  [GC.GRC.CLO_SALE_PERSONS_ID]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:id'],
  },
  [GC.FIELD_CREATED_DATE]: {
    name: 'titles:created-date',
    pivotType: GC.PIVOT_TYPE_YQMD,
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
  },
  [GC.GRC.CLO_CARRIER_NAME]: {
    width: 200,
    name: 'titles:carrier-name',
  },
  [GC.GRC.CLO_CARRIER_US_DOT_NUMBER]: {
    width: 200,
    name: 'titles:carrier-us-dot-number',
  },
  [GC.GRC.CLO_CARRIER_MC_NUMBER]: {
    width: 200,
    name: 'titles:carrier-mc-number',
  },
  [GC.GRC.CLO_CARRIER_TRUCK]: {
    width: 200,
    name: 'titles:carrier-truck',
  },
  [GC.GRC.CLO_CARRIER_TRAILER]: {
    width: 200,
    name: 'titles:carrier-trailer',
  },
  [GC.GRC.CLO_PRIMARY_DRIVER_FIRST_NAME]: {
    width: 200,
    name: ['titles:primary-driver', 'titles:first-name'],
  },
  [GC.GRC.CLO_PRIMARY_DRIVER_LAST_NAME]: {
    width: 200,
    name: ['titles:primary-driver', 'titles:last-name'],
  },
  [GC.GRC.CLO_PRIMARY_DRIVER_LOGIN_ID]: {
    width: 200,
    name: ['titles:primary-driver', 'titles:login-id'],
  },
  [GC.GRC.CLO_SECONDARY_DRIVER_FIRST_NAME]: {
    width: 200,
    name: ['titles:secondary-driver', 'titles:first-name'],
  },
  [GC.GRC.CLO_SECONDARY_DRIVER_LAST_NAME]: {
    width: 200,
    name: ['titles:secondary-driver', 'titles:last-name'],
  },
  [GC.GRC.CLO_SECONDARY_DRIVER_LOGIN_ID]: {
    width: 200,
    name: ['titles:secondary-driver', 'titles:login-id'],
  },
  [GC.GRC.CLO_DRIVER_TRUCK]: {
    width: 200,
    name: 'titles:driver-truck',
  },
  [GC.GRC.CLO_DRIVER_TRAILER]: {
    width: 200,
    name: 'titles:driver-trailer',
  },
  [GC.GRC.CLO_DOCUMENT_TYPE]: {
    width: 250,
    name: ['titles:clo', 'titles:document-type'],
    customComponent: ({ name }: Object) => <span>{R.join(', ', R.or(name, []))}</span>,
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_TYPE]: {
    width: 200,
    name: ['titles:first-event', 'titles:location-type'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 200,
    name: ['titles:first-event', 'titles:template-id'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_NAME]: {
    width: 200,
    name: ['titles:first-event', 'titles:location-name'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    name: ['titles:first-event', 'titles:address1'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS2]: {
    width: 200,
    name: ['titles:first-event', 'titles:address2'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY]: {
    width: 200,
    name: ['titles:first-event', 'titles:city'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE]: {
    width: 200,
    name: ['titles:first-event', 'titles:state'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 200,
    name: ['titles:first-event', 'titles:country'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LOCATION_ZIP]: {
    width: 200,
    name: ['titles:first-event', 'titles:zip'],
  },
  [GC.GRC.CLO_FIRST_EVENT_EARLY_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:early-date'],
  },
  [GC.GRC.CLO_FIRST_EVENT_LATE_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:late-date'],
  },
  [GC.GRC.CLO_FIRST_EVENT_COMPLETE_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:complete-date'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_TYPE]: {
    width: 200,
    name: ['titles:last-event', 'titles:location-type'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 200,
    name: ['titles:last-event', 'titles:template-id'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_NAME]: {
    width: 200,
    name: ['titles:last-event', 'titles:location-name'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    name: ['titles:last-event', 'titles:address1'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS2]: {
    width: 200,
    name: ['titles:last-event', 'titles:address2'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_CITY]: {
    width: 200,
    name: ['titles:last-event', 'titles:city'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_STATE]: {
    width: 200,
    name: ['titles:last-event', 'titles:state'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_COUNTRY]: {
    width: 200,
    name: ['titles:last-event', 'titles:country'],
  },
  [GC.GRC.CLO_LAST_EVENT_LOCATION_ZIP]: {
    width: 200,
    name: ['titles:last-event', 'titles:zip'],
  },
  [GC.GRC.CLO_LAST_EVENT_EARLY_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:early-date'],
  },
  [GC.GRC.CLO_LAST_EVENT_LATE_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:late-date'],
  },
  [GC.GRC.CLO_LAST_EVENT_COMPLETE_DATE]: {
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:complete-date'],
  },
  [GC.GRC.GL_CODE_DISPLAYED_VALUE]: {
    width: 200,
    name: 'titles:gl-code',
  },
  [GC.GRC.NORMALIZED_TOTAL_TOTAL]: {
    width: 200,
    pivotType: 'number',
    name: 'titles:normalized-total',
  },
  [GC.GRC.NORMALIZED_TOTAL_CURRENCY]: {
    width: 200,
    name: 'titles:normalized-currency',
  },
  [GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER]: {
    width: 200,
    name: ['titles:clo', 'titles:container-number'],
  },
  [`${GC.FIELD_CLO}.${GC.FIELD_TOTAL_PICKUP_QUANTITY}`]: {
    width: 200,
    name: ['titles:clo', 'titles:quantity'],
  },
  [GC.GRC.CLO_PINNED_NOTE_TEXT]: {
    width: 200,
    name: ['titles:clo', 'titles:pinned-note'],
  },
  [GC.GRC.CLO_TELS_FLEET_VENDOR_INVOICES_PAY_TYPE]: {
    width: 300,
    type: 'enum',
    styles: { titleInputMaxWidth: 200 },
    name: ['titles:vendor-invoices', 'titles:pay-to-type'],
  },
  ...itemColumnSettingsForCustomerInvoice,
  [`${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_FREIGHT_CLASS}`]: {
    width: 200,
    name: ['titles:items', 'titles:freight-class'],
    customComponent: (props: Object) => {
      const text = R.compose(
        R.join(', '),
        R.map((item: string) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(item, GC.FIELD_VALUE)),
        )(getFreightClassOptions())),
        R.pathOr([], ['data', `${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_FREIGHT_CLASS}`]),
      )(props);

      return (
        <TextComponent maxWidth={180} title={text} display='block' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [`${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_DIMENSIONS_UOM}`]: {
    width: 200,
    name: ['titles:items', 'titles:dimensions-uom'],
    customComponent: ({ data }: Object) => {
      const text = R.compose(
        R.join(', '),
        R.map((item: string) => G.getUomLocale(item)),
        R.pathOr([], [`${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ITEM_DIMENSIONS_UOM}`]),
      )(data);

      return (
        <TextComponent maxWidth={180} title={text} display='block' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [`${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_WHEELBASE_UOM}`]: {
    width: 200,
    name: ['titles:items', 'titles:wheelbase-uom'],
    customComponent: (props: Object) => {
      const text = R.compose(
        R.join(', '),
        R.map((item: string) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(item, GC.FIELD_VALUE)),
        )(ITEM_DIMENSIONS_OPTIONS)),
        R.pathOr([], ['data', `${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_WHEELBASE_UOM}`]),
      )(props);

      return (
        <TextComponent maxWidth={180} title={text} display='block' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [`${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ODOMETER_UOM}`]: {
    width: 200,
    name: ['titles:items', 'titles:odometer-uom'],
    customComponent: (props: Object) => {
      const text = R.compose(
        R.join(', '),
        R.map((item: string) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(item, GC.FIELD_VALUE)),
        )(ITEM_ODOMETER_UOM_OPTIONS)),
        R.pathOr([], ['data', `${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${GC.FIELD_ODOMETER_UOM}`]),
      )(props);

      return (
        <TextComponent maxWidth={180} title={text} display='block' withEllipsis={true}>
          {text}
        </TextComponent>
      );
    },
  },
  [GC.GRC.CLO_TELS_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME]: {
    width: 200,
    name: 'titles:vendor-name',
  },
});

export const printAndSendEmailsColumnSettings = {
  [GC.FIELD_EMAILS]: {
    width: 220,
    name: 'titles:emails',
    customComponent: ({ data, rowIndex, callbackData = {} }: Object) => {
      const { sendEmails, handleChangeEmails } = callbackData;

      const emails = R.pathOr([], [GC.FIELD_EMAILS], data);

      const title = R.join(', ', emails);

      return (
        <Flex justifyContent='space-between'>
          <TextComponent
            title={title}
            color='light.blue'
            withEllipsis={true}
            maxWidth={G.ifElse(sendEmails, 190, 204)}
          >
            {title}
          </TextComponent>
          {
            sendEmails &&
            <Box cursor='pointer' onClick={() => handleChangeEmails({ emails, rowIndex })}>
              {I.pencil(G.getTheme('colors.dark.blue'))}
            </Box>
          }
        </Flex>
      );
    },
  },
  [`${GC.FIELD_CLO}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`]: {
    width: 100,
    name: 'titles:branch-name',
  },
  [GC.FIELD_INVOICE_NUMBER]: {
    width: 100,
    name: 'titles:number',
    customComponent: ({ data }: Object) => (
      <TextComponent
        maxWidth={84}
        cursor='pointer'
        color='light.blue'
        withEllipsis={true}
        title={`${R.prop(GC.FIELD_INVOICE_NUMBER, data)} - ${
          G.getWindowLocale('actions:go-to-clo-details', 'Go To CLO Details')}`}
        onClick={() => {
          G.goToLoadDetailsByConfigAndLoadType(routesMap, R.prop(GC.FIELD_CLO_GUID, data), true);
        }}
      >
        {R.prop(GC.FIELD_INVOICE_NUMBER, data)}
      </TextComponent>
    ),
  },
  [GC.FIELD_INVOICE_DATE]: {
    width: 100,
    name: 'titles:date',
  },
  [GC.GRC.STATUS_DISPLAYED_VALUE]: {
    width: 100,
    name: 'titles:status',
  },
  [GC.GRC.BILL_TO_PAYMENT_TERM]: {
    width: 150,
    name: 'titles:payment-term',
    customComponent: (props: Object) => {
      const paymentTerm = R.path(['data', GC.GRC.BILL_TO_PAYMENT_TERM], props);

      if (G.isNilOrEmpty(paymentTerm)) return null;

      return R.compose(
        R.prop(GC.FIELD_LABEL),
        R.find(R.propEq(paymentTerm, GC.FIELD_VALUE)),
      )(PAYMENT_TERM_OPTIONS);
    },
  },
  [GC.FIELD_TOTAL]: {
    width: 100,
    name: 'titles:total',
    customComponent: (props: Object) => {
      const { total, currency } = R.propOr({}, 'data', props);

      const title = `${G.getCurrencySymbol(currency)} ${G.mathRoundNumber(total)}`;

      return (
        <TextComponent maxWidth={84} title={title} withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_TOTAL_TRIP_DISTANCE]: {
    width: 100,
    name: 'titles:total-distance',
    customComponent: ({ data }: Object) => {
      const totalTripDistance = R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE, data);

      if (G.isNilOrEmpty(totalTripDistance)) return null;

      const title = `${G.mathRoundNumber(totalTripDistance)} ${R.prop(GC.FIELD_TOTAL_TRIP_DISTANCE_UOM, data)}`;

      return (
        <TextComponent maxWidth={84} title={title} withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_TOTAL_TRIP_WEIGHT]: {
    width: 100,
    name: 'titles:total-weight',
    customComponent: ({ data }: Object) => {
      const totalTripWeight = R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT, data);

      if (G.isNilOrEmpty(totalTripWeight)) return null;

      const title = `${G.mathRoundNumber(totalTripWeight)} ${R.prop(GC.FIELD_TOTAL_TRIP_WEIGHT_UOM, data)}`;

      return (
        <TextComponent maxWidth={84} title={title} withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
  [GC.GRC.MODE_DISPLAYED_VALUE]: {
    width: 100,
    name: 'titles:mode',
  },
  [GC.SYSTEM_OBJECT_FIRST_EVENT]: {
    width: 150,
    name: 'titles:first-stop',
    customComponent: ({ data }: Object) => {
      const title = R.compose(
        R.join(' '),
        R.values,
        R.pick([
          GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY,
          GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE,
        ]),
      )(data);

      return (
        <TextComponent maxWidth={134} title={title} withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
  [GC.SYSTEM_OBJECT_LAST_EVENT]: {
    width: 150,
    name: 'titles:last-stop',
    customComponent: ({ data }: Object) => {
      const title = R.compose(
        R.join(' '),
        R.values,
        R.pick([
          GC.GRC.CLO_LAST_EVENT_LOCATION_CITY,
          GC.GRC.CLO_LAST_EVENT_LOCATION_STATE,
        ]),
      )(data);

      return (
        <TextComponent maxWidth={134} title={title} withEllipsis={true}>
          {title}
        </TextComponent>
      );
    },
  },
  [GC.FIELD_COMMENTS]: {
    width: 150,
    name: 'titles:comments',
  },
};
